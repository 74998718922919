import React from 'react'
import './about.css'
import ME from '../../assets/images/working.jpg'
import {RiAwardFill} from 'react-icons/ri'
import {FaUsers} from 'react-icons/fa'
import {RiFoldersFill} from 'react-icons/ri'

const About = () => {
  return (
    <section id="about">
      <div className="section-title">
        

        <h2>About Me</h2>
      </div>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" />
          </div>
        </div>
        <div className="about__content">
          {/* <div className="about__cards">
            <article className="about__card">
              <RiAwardFill className='about__icon' />
              <h5>Experience</h5>
              <small>3+ Years</small>
            </article>
            <article className="about__card">
              <FaUsers className='about__icon' />
              <h5>Clients</h5>
              <small>10+ Clients</small>
            </article>
            <article className="about__card">
              <RiFoldersFill className='about__icon' />
              <h5>Projects</h5>
              <small>15+ Projects</small>
            </article>
          </div> */}
          <p>Full Stack Web and Mobile App Developer from Nepal, with over 3 years of experience. Experienced with all stages of the development cycle for dynamic web and app projects. Having an in-depth knowledge including advanced HTML5, CSS3, React, Django, Flutter and Dart. Have developed several android and web app.</p>
          {/* <a href="" className='btn btn-primary'>Download CV</a> */}
        </div>
      </div>
    </section>
  )
}

export default About