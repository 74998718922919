import React , { useRef, useState } from "react";
import "./contact.css";
import { FiMail } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import { RiMessengerLine } from "react-icons/ri";
import emailjs from '@emailjs/browser';


const Contact = () => {


  const [statusMessage, setStatusMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_e4gq7d6', 'template_bado6hr', form.current, 'AQrJpbmww1A4XbcHn')
    .then(
      (result) => {
        console.log(result.text, result.status);
        
        setStatusMessage("Email sent success");
        
        e.target.reset()
      },
      (error) => {
        console.log(error.text);
        setStatusMessage(`${error.text} happened`);
        
        e.target.reset()
      }
    )
     
  };


  return (
    <section id="contact">
      <div className="section-title">
        <h2>Contact Me</h2>
      </div>

      <div className="container contact__container">
        <div className="contact__options">
          <div className="contact__item-wrapper email__wrapper">
            <article className="contact__option">
            <div className="dot__wrapper">
               <a href="mailto:chaudharyravi290@gmail.com"> <div className="dot">
              <FiMail className="contact__option-icon" /></div></a></div>
              <h4 className="contact__label">Email</h4>
              <h5>chaudharyravi290@gmail.com</h5>
            </article>
          </div>
          <div className="contact__item-wrapper">
            <article className="contact__option">
              <div className="dot__wrapper">
                <a href="https://wa.me/9779824750358"><div className="dot">
                  <BsWhatsapp className="contact__option-icon" />
                </div></a>
              </div>

              <h4 className="contact__label">Whatsapp</h4>
              <h5>+977 9824750358</h5>
            </article>
          </div>
          <div className="contact__item-wrapper">
            <article className="contact__option">
            <div className="dot__wrapper">
              <a href="http://m.me/chaudharyravi290"><div className="dot">
                <RiMessengerLine className="contact__option-icon" />
              </div></a></div>
              <h4 className="contact__label">Messenger</h4>
              <h5>Ravi Chaudhary</h5>
            </article>
          </div>
        </div>
        <div>
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name="name" placeholder="Your Name" />
            <input type="email" name="email" placeholder="Your Email" />
            <textarea
              name="message"
              id=""
              rows="7"
              placeholder="Your Message"
            ></textarea>
            <button type="submit" className="btn btn-primary">
              Send Message
            </button>
          </form>
          
          <p>{statusMessage}</p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
