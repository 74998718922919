import React from "react";
import "./experience.css";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const Experience = () => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 2,
    borderRadius: 3,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#0EE6B7" : "#308fe8",
    },
  }));

  return (
    <section id="experience">
      <div className="section-title">
        <h2>My Experience</h2>
      </div>

      <div className="container experience__container">
        <div className="experience__frontend">
          <div className="experience__wrapper">
            <h3>Frontend Development</h3>
            <div className="experience__content">
              <article className="experience__details">
                <BsFillPatchCheckFill className="experience__details-icon" />
                <div className="experience__item">
                  <h4>HTML</h4>
                  <BorderLinearProgress
                    className="progress"
                    variant="determinate"
                    value={80}
                  />
                </div>
              </article>
              <article className="experience__details">
                <BsFillPatchCheckFill className="experience__details-icon" />
                <div className="experience__item">
                  <h4>CSS</h4>
                  <BorderLinearProgress
                    className="progress"
                    variant="determinate"
                    value={70}
                  />
                </div>
              </article>
              <article className="experience__details">
                <BsFillPatchCheckFill className="experience__details-icon" />
                <div className="experience__item">
                  <h4>JS</h4>
                  <BorderLinearProgress
                    className="progress"
                    variant="determinate"
                    value={50}
                  />
                </div>
              </article>
              <article className="experience__details">
                <BsFillPatchCheckFill className="experience__details-icon" />
                <div className="experience__item">
                  <h4>React</h4>
                  <BorderLinearProgress
                    className="progress"
                    variant="determinate"
                    value={80}
                  />
                </div>
              </article>
            </div>
          </div>
        </div>
        <div className="experience__backend">
        <div className="experience__wrapper">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div className="experience__item">
                <h4>Django</h4>
                <BorderLinearProgress
                  className="progress"
                  variant="determinate"
                  value={70}
                />
              </div>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div className="experience__item">
                <h4>Laravel</h4>
                <BorderLinearProgress
                  className="progress"
                  variant="determinate"
                  value={80}
                />
              </div>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div className="experience__item">
                <h4>Node Js</h4>
                <BorderLinearProgress
                  className="progress"
                  variant="determinate"
                  value={60}
                />
              </div>
            </article>
          </div>
        </div>
        </div>
        <div className="experience__backend">
        <div className="experience__wrapper">
          <h3>App Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div className="experience__item">
                <h4>Flutter</h4>
                <BorderLinearProgress
                  className="progress"
                  variant="determinate"
                  value={75}
                />
              </div>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div className="experience__item">
                <h4>Dart</h4>
                <BorderLinearProgress
                  className="progress"
                  variant="determinate"
                  value={82}
                />
              </div>
            </article>
            <article className="experience__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div className="experience__item">
                <h4>Kotlin</h4>
                <BorderLinearProgress
                  className="progress"
                  variant="determinate"
                  value={75}
                />
              </div>
            </article>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
