import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/images/ravi.png'
import Particles from "react-tsparticles";
import { loadFull as particlesInit,loadFull as particlesLoaded } from "tsparticles";

import {FaLinkedinIn} from 'react-icons/fa'
import {FaGithub} from 'react-icons/fa'
import {FaFacebookF} from 'react-icons/fa'

import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#0EE6B7",
              opacity: 0.1,
            },
            links: {
              color: "#0EE6B7",
              distance: 150,
              enable: true,
              opacity: 0.2,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 20,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
      {/* <HeaderSocials app="here app" /> */}
      {/* <div className="container header__container">
            <h2>Hello I'm</h2>
            <h1>Ravi Chaudhary</h1>
            <h3 className="text-light">Fullstack Developer</h3>
            <CTA />
            <div className="me">
                <img src={ME} alt="Ravi Chaudhary" />
            </div>
            <a href="#contact" className='scroll__down'>Scroll Down</a>
        </div> */}

      <div className="container header__container">
        <div className="header__content">
          <div className="me_info">
            <h2 className="text-green">Hello</h2>
            <h1 className="typed-out">
              I'm Ravi Chaudhary<span className="blink">_</span>{" "}
            </h1>
          </div>

          <div className="me">
            <p className="text-grey">//About Me</p>
            <p className="text-light">
              <span className="text-purple">Bio</span> &#123;
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;<span className="text-light">First_Name:</span>{" "}
              <span className="text-blue">Ravi;</span>
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;<span className="text-light">Last_Name:</span>{" "}
              <span className="text-blue">Chaudhary;</span>
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;<span className="text-light">Profession:</span>{" "}
              <span className="text-blue">Full Stack Developer;</span>
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;<span className="text-light">Experience:</span>{" "}
              <span className="text-blue">3+ Years;</span>
            </p>
            <p className="text-light">&#125;</p>
          </div>
          
        </div>

        <div className="me-image">
          <img src={ME} alt="Ravi Chaudhary" />
          <div className="inner">
            <div className="inner1">
            <div className="dot">
              <a href="https://www.linkedin.com/in/ravi-chaudhary-73b4b717a/" target="_blank"><FaLinkedinIn /></a>
            </div>
            <div className="dot">
            <a href="https://github.com/chyravi290" target="_blank"><FaGithub /></a>
            </div>
            <div className="dot">
            <a href="https://www.facebook.com/chaudharyravi290" target="_blank"><FaFacebookF /></a>
            </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header