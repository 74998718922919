import React, { Component } from "react";
import Testimonial from "react-testimonial";
import "./testimonials.css";
import ME from "../../assets/images/client.jpg";

// import Swiper core and required modules
import { Navigation, Pagination, Autoplay, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Testimonials = () => {
  return (
    <section id="testimonials">
      <div className="section-title">
        <h2>Client Reviews</h2>
      </div>

      <div className="container testimonials__container">
        <Testimonial>
          <div className="card content mx-auto">
            <div className="card-body">
              <div className="client__avatar">
                <img src={ME} alt="About" />
              </div>
              <span className="client__review">
              Without doubt one of the most talented programmers out there. Smart, trustworthy and professional. You won't be disappointed.
              </span>
              <h2 className="client__intro">Subas Bhusal</h2>
              <h4>Owner of Sabxa Online Store</h4>
            </div>
          </div>
          <div className="card content mx-auto">
            <div className="card-body">
              <div className="client__avatar">
                <img src={ME} alt="About" />
              </div>
              <span className="client__review">
              Without doubt one of the most talented programmers out there. Smart, trustworthy and professional. You won't be disappointed.
              </span>
              <h2 className="client__intro">Subas Bhusal</h2>
              <h4>Owner of Sabxa Online Store</h4>
            </div>
          </div>
          
        </Testimonial>
      </div>
    </section>
  );
};

export default Testimonials;
