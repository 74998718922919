import React from 'react'
import './services.css'
import {BsCheck} from 'react-icons/bs'

const Services = () => {
  return (
    <section id='services'>
      <div className="section-title">
        
        <h2>My Services</h2>
      </div>
      <div className="container services__container">
        <article className="service product__design">
          <div className="service__wrapper">
          <h3>Product Design</h3>
          <small>I work with certain design tools to create high-fidelity designs and prototypes. I design accessible and usable products which aid business growth.</small>
          </div>
        </article>
        <article className="service">
        <div className="service__wrapper">
          <h3>Website Design/Development</h3>
          <small>I use various web technologies to develop attractive websites which converts visitors to customers. I develop creative and responsive website layouts.</small>
          </div>
        </article>
        <article className="service">
        <div className="service__wrapper">
          <h3>Mobile App Development</h3>
          <small>Experience in creating high performing, digitally transformative and feature-packed mobile applications for Android and iOS devices.</small>
          </div>
        </article>
        
      </div>
    </section>
  )
}

export default Services