import React from 'react'
import About from './components/about/About'
import Nav from './components/nav/Nav'
import Contact from './components/contact/Contact'
import Experience from './components/experience/Experience'
import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import Portfolio from './components/portfolio/Portfolio'
import Services from './components/services/Services'
import Testimonials from './components/testimonials/Testimonials'
import AnimatedCursor from 'react-animated-cursor'



const App = () => {
  return (
    <>

    
    {/* <AnimatedCursor
    
      innerSize={12}
      outerSize={12}
      color='14, 230, 183'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}
     /> */}
    <Header />
    <Nav />
    <About />
    <Experience />
    <Services />
    {/* <Portfolio /> */}
    <Testimonials />
    <Contact />
    <Footer />
    </>
  )
}

export default App