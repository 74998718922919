import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaFacebookSquare} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/ravi-chaudhary-73b4b717a/" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/chyravi290" target="_blank"><FaGithub /></a>
        <a href="https://www.facebook.com/chaudharyravi290" target="_blank"><FaFacebookSquare /></a>
    </div>
  )
}

export default HeaderSocials