import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaFacebookSquare} from 'react-icons/fa'
import {AiFillInstagram} from 'react-icons/ai'
import {FaTwitter} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      
      <a href="#" className="footer__logo">Ravi Chaudhary</a>
      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About Me</a></li>
        <li><a href="#experience">My Experience</a></li>
        <li><a href="#services">My Services</a></li>
        {/* <li><a href="#portfolio">Portfolio</a></li> */}
        <li><a href="#testimonials">Client Reviews</a></li>
        <li><a href="#contact">Contact Me</a></li>
      </ul>
      <div className="footer__socials">
        <a href="https://www.facebook.com/chaudharyravi290"><FaFacebookSquare /></a>
        
        <a href="https://www.linkedin.com/in/ravi-chaudhary-73b4b717a/"><BsLinkedin /></a>
        <a href="https://github.com/chyravi290"><FaGithub /></a>
      </div>
      <div className="footer__copyright">
        <small>&copy; Ravi Chaudhary. All Rights Reserved.</small>
      </div>
    </footer>
  )
}

export default Footer